import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Layout from '../components/Layout';
import '../styles/Login.css';
import '../styles/base.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setMessage('Inloggen is gelukt!');
      setShowPopup(true);

      setTimeout(() => {
        setShowPopup(false);
        navigate('/dashboard');
      }, 2000);
    } catch (err) {
      setError('Er is een fout opgetreden bij het inloggen. Controleer je inloggegevens.');
      setPassword(''); // Leeg wachtwoordveld bij fout
      console.error('Login error:', err.message);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Layout>
      <div className="form-container form-container-login">
        <h2 className="heading">Inloggen</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label className="label">E-mailadres:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`input input-login ${error ? 'input-error' : ''}`}
              required
              autoFocus
            />
          </div>

          <div className="form-group">
            <label className="label">Wachtwoord:</label>
            <div className="password-container">
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`input input-login input-password ${error ? 'input-error' : ''}`}
                required
              />
              <button
                type="button"
                className="toggle-password"
                onClick={togglePasswordVisibility}
                aria-label={showPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>

          <button type="submit" className="button button-login" disabled={loading}>
            {loading ? 'Inloggen...' : 'Inloggen'}
          </button>

          {error && (
            <p className="error" role="alert" aria-live="assertive">
              {error}
            </p>
          )}

          <div className="separator">OF</div>
          <button
            type="button"
            onClick={() => navigate('/register')}
            className="button button-register"
          >
            Registreren
          </button>
        </form>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>{message}</p>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Login;
