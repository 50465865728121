import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebaseConfig'; 
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut as firebaseSignOut,
  sendEmailVerification,
  PhoneAuthProvider,
  multiFactor,
  RecaptchaVerifier,
  setPersistence,
  browserSessionPersistence
} from 'firebase/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set session persistence to session only
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // Observe auth state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setCurrentUser(user);
          setIsAuthenticated(!!user);
          setLoading(false);
        });
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error('Error setting persistence:', error.message);
      });
  }, []);

  const initRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container', 
        { size: 'invisible' }, 
        auth
      );
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (multiFactor(user).enrolledFactors.length > 0) {
        console.log('MFA required. Starting verification.');
        
        const resolver = multiFactor(user).resolver;
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier);

        const verificationCode = prompt("Enter the verification code sent to your phone:");
        const credential = PhoneAuthProvider.credential(verificationId, verificationCode);

        await resolver.resolveSignIn(credential);
        console.log('MFA verification successful!');
      } else {
        console.log('Login successful without MFA.');
      }

      setCurrentUser(user);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Login error:', error.message);
    }
  };

  const signup = async (email, password, displayName, phoneNumber) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, { displayName });
      await sendEmailVerification(user);
      
      initRecaptcha();

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneNumber, window.recaptchaVerifier);

      const verificationCode = prompt("Enter the verification code sent to your phone:");
      const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await multiFactor(user).enroll(phoneCredential, 'Phone number');

      console.log('User successfully registered with MFA.');
    } catch (error) {
      console.error('Signup error:', error.message);
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Reset email sent, please check your inbox.');
    } catch (error) {
      console.error('Password reset error:', error.message);
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setIsAuthenticated(false);
      console.log('User successfully logged out');
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  const value = {
    currentUser,
    isAuthenticated,
    setIsAuthenticated, // Voeg deze regel toe
    login,
    signup,
    resetPassword,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      <div id="recaptcha-container"></div>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
