import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; 
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import Layout from '../components/Layout';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../styles/Register.css';
import '../styles/base.css';

const Register = () => {
  const { setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (password !== confirmPassword) {
      setError('Wachtwoorden komen niet overeen.');
      setLoading(false);
      return;
    }

    try {
      // Stel de tenant ID in op de auth instantie
      auth.tenantId = "StockPilot-120ez"; // Vervang dit door jouw tenant ID

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: username });
      await sendEmailVerification(user);

      setError('Registratie gelukt! Controleer je e-mail om je account te verifiëren.');
      setIsAuthenticated(true);
      navigate('/dashboard');
    } catch (err) {
      setError(err.message); // Geef specifieke foutboodschap
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <Layout>
      <div className="container">
        <div className="form-container form-container-register">
          <h2 className="heading">Registreren</h2>
          <p className='header-text'>Vul hier uw gegevens in.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                Voor en Achternaam:
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="input input-register"
                  required
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                E-mailadres:
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input input-register"
                  required
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Wachtwoord:
                <div className="password-container">
                  <input
                    type={showPassword ? 'text' : 'password'} 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input input-register"
                    required
                  />
                  <button 
                    type="button"
                    className="eye-icon" 
                    onClick={togglePasswordVisibility}
                    aria-label={showPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </label>
            </div>
            <div className="form-group">
              <label>
                Bevestig Wachtwoord:
                <div className="password-container">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'} 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="input input-register"
                    required
                  />
                  <button
                    type="button"
                    className="eye-icon"
                    onClick={toggleConfirmPasswordVisibility}
                    aria-label={showConfirmPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </label>
            </div>
            <button type="submit" className="button button-register" disabled={loading}>
              {loading ? 'Registreren...' : 'Maak een account aan'}
            </button>
          </form>
          {error && <p className="error">{error}</p>}

          <button className="back-button" onClick={() => navigate('/login')}>
            Terug naar Inloggen
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
