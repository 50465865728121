// src/components/Layout.js
import React from 'react';
import '../styles/Layout.css';


const Layout = ({ children }) => {
  return (
    <div>

      <main>{children}</main>

    </div>
  );
};

export default Layout;
