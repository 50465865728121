import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.png';

const Header = () => {
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const profileRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (err) {
      console.error('Uitloggen mislukt:', err);
    }
  };

  // Handle mobile screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for window resize

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close dropdown when clicking outside of the profile
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Check if we're on login, register, or forgot password page
  const isAuthPage = window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/forgot-password';

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>

      {/* Only show profile dropdown if the user is logged in and not on auth pages */}
      {!isAuthPage && (
        <div className="profile" ref={profileRef} onClick={toggleDropdown}>
          {!isMobile ? (
            <>
              <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
              <div>
                <p className="name">{currentUser?.displayName || 'Gebruiker'}</p>
                <span className="status">Je bent ingelogd</span>
              </div>
            </>
          ) : (
            <FontAwesomeIcon icon={faBars} className='Bars' />
          )}
          {dropdownVisible && (
            <div className={`dropdown ${isMobile ? 'mobile-dropdown' : ''}`}>
              {isMobile && <p className="name">{currentUser?.displayName || 'Gebruiker'}</p>}
              <button onClick={() => navigate('/dashboard')} className="dropdown-button">
                Dashboard
              </button>
              <button onClick={handleSignOut} className="dropdown-button">
                Uitloggen
              </button>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
