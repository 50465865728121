// src/pages/NotFound.js
import React from 'react';
import '../styles/NotFound.css'; // Zorg ervoor dat het pad correct is

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h2 className="not-found-title">4 0 4</h2>
      <p className="not-found-message">Page not found.</p>
    </div>
  );
};

export default NotFound;
