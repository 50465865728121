// src/pages/TradeForm.jsx
import React, { useState } from 'react';
import '../styles/TradeForm.css';
import DayTradeTable from '../tables/DayTradeTable';
import SwingTradeTable from '../tables/SwingTradeTable';

const TradeTableContent = ({ trades }) => {
  const [selectedTab, setSelectedTab] = useState('daytrade');

  // Group trades by pairs of strategies
  const strategyGroups = {
    daytrade: trades.filter(trade => ['Reversel Long', 'Reversel Short', 'Breakout Long', 'Breakout Short', 'Long met the Trend', 'Short met the Trend'].includes(trade.strategy)),
    swingtrade: trades.filter(trade => ['Swing hamer boven', 'Swing hamer onder'].includes(trade.strategy)),
    reversel: trades.filter(trade => ['Reversel Long', 'Reversel Short'].includes(trade.strategy)),
    breakout: trades.filter(trade => ['Breakout Long', 'Breakout Short'].includes(trade.strategy)),
    trend: trades.filter(trade => ['Long met the Trend', 'Short met the Trend'].includes(trade.strategy))
  };

  return (
    <div className="trade-table-wrapper">
      {/* Tabs section */}
      <div className="tabs-container">
        <button
          className={`tab-button ${selectedTab === 'daytrade' ? 'active' : ''}`}
          onClick={() => setSelectedTab('daytrade')}
        >
          Daytrade
        </button>
        <button
          className={`tab-button ${selectedTab === 'swingtrade' ? 'active' : ''}`}
          onClick={() => setSelectedTab('swingtrade')}
        >
          Swingtrade
        </button>
        <button
          className={`tab-button ${selectedTab === 'reversel' ? 'active' : ''}`}
          onClick={() => setSelectedTab('reversel')}
        >
          Reversel Long / Short
        </button>
        <button
          className={`tab-button ${selectedTab === 'breakout' ? 'active' : ''}`}
          onClick={() => setSelectedTab('breakout')}
        >
          Breakout Long / Short
        </button>
        <button
          className={`tab-button ${selectedTab === 'trend' ? 'active' : ''}`}
          onClick={() => setSelectedTab('trend')}
        >
          Long / Short met the Trend
        </button>
      </div>

      {/* Scrollable table content */}
      <div className="trade-table-content">
        {selectedTab === 'daytrade' ? (
          <DayTradeTable trades={strategyGroups.daytrade} />
        ) : selectedTab === 'swingtrade' ? (
          <SwingTradeTable trades={strategyGroups.swingtrade} />
        ) : (
          <DayTradeTable trades={strategyGroups[selectedTab]} />
        )}
      </div>
    </div>
  );
};

export default TradeTableContent;
