import React from 'react';


const Footer = () => {
  return (
    <footer className="footer">
      <p>Copyright © {new Date().getFullYear()} franspetervanlimburg.nl</p>
    </footer>
  );
};

export default Footer;
