import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TradeForm from './pages/TradeForm';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword'; // Nieuwe import
import NotFound from './pages/NotFound'; // Import de NotFound component
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header'; // Voeg Header toe
import Footer from './components/Footer'; // Voeg Footer toe
import './App.css'; // Voeg deze regel toe

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <div className="content"> {/* Zorg dat de inhoud tussen Header en Footer valt */}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password" element={<ResetPassword />} /> {/* Nieuwe route */}
            {/* Beschermde routes */}
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <TradeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} /> {/* Voeg deze regel toe */}
          </Routes>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
