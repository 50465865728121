import React, { useState } from 'react';
import Layout from '../components/Layout';
import '../styles/TradeForm.css';
import TradeTableContent from './TradeTableContent'; 


const TradeForm = () => {
  const [trades, setTrades] = useState([]);
  const [formValues, setFormValues] = useState({
    date: '',
    symbol: '',
    type: 'Short',
    timeOfTrade: 'Evening',
    strategy: 'Reversel Long',
    entry: '',
    stopLoss: '',
    suggestedStop: '',
    sharesAtEntry: '',
    partialPrice: '',
    amountSharesSold: '',
    finalStopExit: '',
    amountSharesSoldFinal: '',
    invested: '',
    sold: '',
    totalPL: '',
    gainPercentage: '',
    totalRU: '',
    twoROrNothing: '',
    planA: '',
    screenshots: null,
    commission: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleNumericInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'entry' || name === 'stopLoss' || name === 'suggestedStop') {
      const cleanedValue = value.replace(',', '.');
      if (/^-?\d*[.,]?\d*$/.test(cleanedValue) || cleanedValue === '') {
        setErrorMessage('');
        setFormValues((prevValues) => ({ ...prevValues, [name]: cleanedValue }));
      } else {
        setErrorMessage('Alleen numeriek!');
      }
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      setErrorMessage('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errorMessage) {
      setTrades((prevTrades) => [...prevTrades, formValues]);
      setFormValues({
        date: '',
        symbol: '',
        type: 'Short',
        timeOfTrade: 'Evening',
        strategy: 'Reversel Long',
        entry: '',
        stopLoss: '',
        suggestedStop: '',
        sharesAtEntry: '',
        partialPrice: '',
        amountSharesSold: '',
        finalStopExit: '',
        amountSharesSoldFinal: '',
        invested: '',
        sold: '',
        totalPL: '',
        gainPercentage: '',
        totalRU: '',
        twoROrNothing: '',
        planA: '',
        screenshots: null,
        commission: '',
      });
    }
  };

  const handleScreenshotChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setFormValues((prevValues) => ({ ...prevValues, screenshots: url }));
    } else {
      setFormValues((prevValues) => ({ ...prevValues, screenshots: null }));
    }
  };

  return (
    <Layout>
      <div className="trade-form-container">
        <div className='Trade-heading'>Trade form</div>
        <form onSubmit={handleSubmit} className="trade-form">          
            <div className="trade-form-group">
              <label>DATE:</label>
              <input
                type="date"
                name="date"
                value={formValues.date}
                onChange={handleNumericInputChange}
                className="trade-form-input"
                required
              />
            </div>
            <div className="trade-form-group">
              <label>SYMBOL:</label>
              <input
                type="text"
                name="symbol"
                value={formValues.symbol}
                onChange={handleNumericInputChange}
                className="trade-form-input"
                required
              />
            </div> 
            <div className="trade-form-group">
              <label>TYPE:</label>
              <select
                name="type"
                value={formValues.type}
                onChange={handleNumericInputChange}
                className="trade-form-input"
                required
              >
                <option value="Short">Short</option>
                <option value="Long">Long</option>
              </select>
            </div>
            <div className="trade-form-group">
              <label>TIME OF TRADE:</label>
              <select
                name="timeOfTrade"
                value={formValues.timeOfTrade}
                onChange={handleNumericInputChange}
                className="trade-form-input"
                required
              >
                <option value="Morning">Morning</option>
                <option value="Lunch">Lunch</option>
                <option value="Evening">Evening</option>
                <option value="End of Day">End of Day</option>
              </select>
            </div>
            <div className="trade-form-group">
              <label>STRATEGY:</label>
              <select
                name="strategy"
                value={formValues.strategy}
                onChange={handleNumericInputChange}
                className="trade-form-input"
                required
              >
                <option value="Reversel Long">Reversel Long</option>
                <option value="Reversel Short">Reversel Short</option>
                <option value="Break out Long">Break out Long</option>
                <option value="Break out Short">Break out Short</option>
                <option value="Long met the Trend">Long met the Trend</option>
                <option value="Short met the Trend">Short met the Trend</option>
                <option value="Swing hamer boven">Swing hamer boven</option>
                <option value="Swing hamer onder">Swing hamer onder</option>
              </select>
            </div>
            <div className="trade-form-group">
              <label>ENTRY:</label>
              <div className="trade-input-with-symbol">
                <span className="trade-euro-symbol">€</span>
                <input
                  type="text"
                  name="entry"
                  value={formValues.entry}
                  onChange={handleNumericInputChange}
                  className="trade-form-input-money"
                  required
                />
              </div>
            </div>
            <div className="trade-form-group">
              <label>STOP LOSS:</label>
              <div className="trade-input-with-symbol">
                <span className="trade-euro-symbol">€</span>
                <input
                  type="text"
                  name="stopLoss"
                  value={formValues.stopLoss}
                  onChange={handleNumericInputChange}
                  className="trade-form-input-money"
                  required
                />
              </div>
            </div>
            <div className="trade-form-group">
              <label>SUGGESTED STOP:</label>
              <div className="trade-input-with-symbol">
                <span className="trade-euro-symbol">€</span>
                <input
                  type="text"
                  name="suggestedStop"
                  value={formValues.suggestedStop}
                  onChange={handleNumericInputChange}
                  className="trade-form-input-money"
                  required
                />
              </div>
            </div><div className="trade-form-group">
              <label>SHARES AT ENTRY:</label>
              <input
                type="number"
                name="sharesAtEntry"
                value={formValues.sharesAtEntry}
                onChange={handleNumericInputChange}
                className="trade-form-input"
                required
              />
            </div>
            <div className="trade-form-group">
              <label>YOUR PARTIAL PRICE:</label>
              <div className="trade-input-with-symbol">
                <span className="trade-euro-symbol">€</span>
                <input
                  type="text"
                  name="partialPrice"
                  value={formValues.partialPrice}
                  onChange={handleNumericInputChange}
                  className="trade-form-input-money"
                />
              </div>
            </div>
            <div className="trade-form-group">
              <label>AMOUNT SHARES SOLD:</label>
              <input
                type="number"
                name="amountSharesSold"
                value={formValues.amountSharesSold}
                onChange={handleNumericInputChange}
                className="trade-form-input"
              />
            </div>
            <div className="trade-form-group">
              <label>YOUR FINAL STOP/EXIT:</label>
              <div className="trade-input-with-symbol">
                <span className="trade-euro-symbol">€</span>
                <input
                  type="text"
                  name="finalStopExit"
                  value={formValues.finalStopExit}
                  onChange={handleNumericInputChange}
                  className="trade-form-input-money"
                />
              </div>
            </div>

            <div className="trade-form-group">
              <label>AMOUNT SHARES SOLD:</label>
              <input
                type="number"
                name="amountSharesSoldFinal"
                value={formValues.amountSharesSoldFinal}
                onChange={handleNumericInputChange}
                className="trade-form-input"
              />
            </div>
            <div className="trade-form-group">
              <label>$ INVESTED:</label>
              <div className="trade-input-with-symbol">
                <span className="trade-euro-symbol">€</span>
                <input
                  type="text"
                  name="invested"
                  value={formValues.invested}
                  onChange={handleNumericInputChange}
                  className="trade-form-input-money"
                />
              </div>
            </div>
            <div className="trade-form-group">
              <label>$ SOLD:</label>
              <div className="trade-input-with-symbol">
                <span className="trade-euro-symbol">€</span>
                <input
                  type="text"
                  name="sold"
                  value={formValues.sold}
                  onChange={handleNumericInputChange}
                  className="trade-form-input-money"
                />
              </div>
            </div>
            <div className="trade-form-group">
              <label>TOTAL P/L:</label>
              <div className="trade-input-with-symbol">
                <span className="trade-euro-symbol">€</span>
                <input
                  type="text"
                  name="totalPL"
                  value={formValues.totalPL}
                  onChange={handleNumericInputChange}
                  className="trade-form-input-money"
                />
              </div>
            </div>
            <div className="trade-form-group">
              <label>% GAIN:</label>
              <input
                type="text"
                name="gainPercentage"
                value={formValues.gainPercentage}
                onChange={handleNumericInputChange}
                className="trade-form-input"
              />
            </div>
            <div className="trade-form-group">
              <label>TOTAL RU +/-:</label>
              <input
                type="text"
                name="totalRU"
                value={formValues.totalRU}
                onChange={handleNumericInputChange}
                className="trade-form-input"
              />
            </div>
            <div className="trade-form-group">
              <label>2R OR NOTHING:</label>
              <input
                type="text"
                name="twoROrNothing"
                value={formValues.twoROrNothing}
                onChange={handleNumericInputChange}
                className="trade-form-input"
              />
            </div>
            <div className="trade-form-group">
              <label>PLAN A:</label>
              <input
                type="text"
                name="planA"
                value={formValues.planA}
                onChange={handleNumericInputChange}
                className="trade-form-input"
              />
            </div>
            <div className="trade-form-group">
              <label>SCREENSHTS:</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleScreenshotChange}
                className="trade-form-input"
              />
              {formValues.screenshots && (
                <img src={formValues.screenshots} alt="Screenshot preview" style={{ width: '100%', marginTop: '10px' }} />
              )}
            </div>
            <div className="trade-form-group">
                <label>COMMISSION:</label>
                <div className="trade-input-with-symbol">
                  <span className="trade-euro-symbol">€</span>
                  <input
                    type="text"
                    name="commission"
                    value={formValues.commission}
                    onChange={handleNumericInputChange}
                    className="trade-form-input-money"
                  />
                </div>
              </div>
          <div class="trade-submit-button-container">
            <button type="submit" className="trade-submit-button">Submit Trade</button>
          </div>
          {errorMessage && <div className="trade-error-message">{errorMessage}</div>}
        </form>


        {/* Display trades table */}

        <div className="table-container">
        <TradeTableContent trades={trades} />
        </div>
      </div>

    </Layout>
  );
};

export default TradeForm;
