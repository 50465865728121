import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { updateProfile, deleteUser, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout'; 
import '../styles/Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [name, setName] = useState(currentUser?.displayName || '');
  const [email, setEmail] = useState(currentUser?.email || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // State for error messages

  useEffect(() => {
    setName(currentUser?.displayName || '');
    setEmail(currentUser?.email || '');
  }, [currentUser]);

  const handleSave = async () => {
    setError('');
    try {
      await updateProfile(auth.currentUser, { displayName: name });
      if (password) {
        await updatePassword(auth.currentUser, password);
        alert('Wachtwoord is succesvol bijgewerkt.');
      }
      alert('Accountgegevens zijn opgeslagen!');
    } catch (err) {
      console.error(err.message);
      setError('Er is een fout opgetreden bij het opslaan.'); // Set error message
    }
  };

  const handleDeleteAccount = async () => {
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await deleteUser(auth.currentUser);
      alert('Account succesvol verwijderd!');
      navigate('/login');
    } catch (err) {
      console.error(err.message);
      if (err.code === 'auth/wrong-password') {
        setError('Ongeldig wachtwoord. Probeer het opnieuw.');
      } else {
        setError('Er is een fout opgetreden. Probeer het later opnieuw.');
      }
    }
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="form-container">
          <h1 className="title">Dashboard</h1>
          <label className="label">
            Naam:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input"
              placeholder="Naam"
              required
            />
          </label>
          <label className="label">
            E-mailadres:
            <input
              type="email"
              value={email}
              className="input"
              readOnly
            />
          </label>
          <label className="label">
            Nieuw Wachtwoord:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input"
              placeholder="Nieuw Wachtwoord"
            />
          </label>
          {error && <p className="error-message">{error}</p>} {/* Display error messages */}
          <div className="button-container">
            <div className="save-delete-container">
              <button onClick={handleSave} className="save-button">
                Opslaan
              </button>
              <button onClick={handleDeleteAccount} className="delete-button">
                Account Verwijderen
              </button>
            </div>
            <button onClick={handleBackToHome} className="back-button">
              Terug naar Home
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
