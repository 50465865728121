import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { confirmPasswordReset } from 'firebase/auth';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Layout from '../components/Layout'; 
import '../styles/ResPas.css';
import '../styles/base.css';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get('oobCode');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setError('De wachtwoorden komen niet overeen.');
      setLoading(false);
      return;
    }

    if (!oobCode) {
      setError('Ongeldige resetlink. OOB-code ontbreekt.');
      setLoading(false);
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage('Wachtwoord succesvol gereset!');

      // Redirect to login page after a short delay and close the window
      setTimeout(() => {
        navigate('/login'); // Redirect to login page
        window.close(); // Close the reset password window
      }, 2000); // Delay for 2 seconds
    } catch (err) {
      setError('Fout bij het resetten van het wachtwoord.');
      console.error(err);
    }

    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Layout>
        <div className="container">
            <div className="form-container">          
                <h2 className="heading">Wachtwoord Resetten</h2>
                <p className="instruction">Voer een nieuw wachtwoord in.</p> {/* Nieuwe instructietekst */}
                <form onSubmit={handleSubmit}>
            <label>
              Nieuw Wachtwoord:
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="input"
                  required
                />
                <span 
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </label>
            <label>
              Bevestig Wachtwoord:
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="input"
                  required
                />
                <span 
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </label>
            <button type="submit" className="button" disabled={loading}>
              Wachtwoord Resetten
            </button>
            </form>
                {message && <p className="success">{message}</p>}
                {error && <p className="error">{error}</p>}
            </div>
        </div>
    </Layout>
);
};

export default ResetPassword;
