// src/tables/SwingTradeTable.jsx
import React from 'react';
import '../styles/TradeForm.css';

const SwingTradeTable = ({ trades }) => {
  return (
    <table className="trade-table">
      <thead>
        <tr>
          <th>DATE</th>
          <th>SYMBOL</th>
          <th>TYPE</th>
          <th>TIME</th>
          <th>STRATEGY</th>
          <th>ENTRY</th>
          <th>STOP LOSS</th>
          <th>SUGGESTED STOP</th>
          <th>SHARES AT ENTRY</th>
          <th>PARTIAL PRICE</th>
          <th>AMOUNT SHARES SOLD</th>
          <th>FINAL STOP/EXIT</th>
          <th>AMOUNT SHARES SOLD</th>
          <th>$ INVESTED</th>
          <th>$ SOLD</th>
          <th>TOTAL P/L</th>
          <th>% GAIN</th>
          <th>TOTAL RU +/-</th>
          <th>2R OR NOTHING</th>
          <th>PLAN A</th>
          <th>SCREENSHOTS</th>
          <th>COMMISSION</th>
        </tr>
      </thead>
      <tbody>
        {trades.map((trade, index) => (
          <tr key={index}>
            <td>{trade.date}</td>
            <td>{trade.symbol}</td>
            <td>{trade.type}</td>
            <td>{trade.timeOfTrade}</td>
            <td>{trade.strategy}</td>
            <td>{trade.entry}</td>
            <td>{trade.stopLoss}</td>
            <td>{trade.suggestedStop}</td>
            <td>{trade.sharesAtEntry}</td>
            <td>{trade.partialPrice}</td>
            <td>{trade.amountSharesSold}</td>
            <td>{trade.finalStopExit}</td>
            <td>{trade.amountSharesSoldFinal}</td>
            <td>{trade.invested}</td>
            <td>{trade.sold}</td>
            <td>{trade.totalPL}</td>
            <td>{trade.gainPercentage}</td>
            <td>{trade.totalRU}</td>
            <td>{trade.twoROrNothing}</td>
            <td>{trade.planA}</td>
            <td>{trade.screenshots && <img src={trade.screenshots} alt="Trade Screenshot" className="trade-table-screenshot" />}</td>
            <td>{trade.commission}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SwingTradeTable;
