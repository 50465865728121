// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Importeer opslag

// Firebase configuratie
const firebaseConfig = {
  apiKey: "AIzaSyDWCjiibq0jVIFHWpwMeF54OTXr0TZKZRU",
  authDomain: "stockpilot-b2d8d.firebaseapp.com",
  projectId: "stockpilot-b2d8d",
  storageBucket: "stockpilot-b2d8d.appspot.com",
  messagingSenderId: "748874469510",
  appId: "1:748874469510:web:505e493e1a808efcc90107",
  measurementId: "G-1223R0RE8H"
};

// Initialiseer Firebase
const app = initializeApp(firebaseConfig);

// Initialiseer Firebase Authentication
const auth = getAuth(app); // Geen tenant-ID instellen

// Initialiseer Firebase Storage
const storage = getStorage(app);

// Exporteer de auth- en storage-objecten
export { auth, storage };
